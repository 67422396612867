@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;
// @include foundation-grid;
// @include foundation-flex-grid;

// Generic components
@include foundation-button;
@include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-switch;
@include foundation-table;
// Basic components
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
@include foundation-dropdown;
@include foundation-pagination;
@include foundation-tooltip;

// Containers
@include foundation-accordion;
@include foundation-media-object;
@include foundation-orbit;
@include foundation-responsive-embed;
@include foundation-tabs;
@include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-accordion-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu;

// Layout components
@include foundation-off-canvas;
@include foundation-reveal;
@include foundation-sticky;
@include foundation-title-bar;
@include foundation-top-bar;

// Helpers
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;

// Motion UI
@include motion-ui-transitions;
@include motion-ui-animations;

h1, h2, h3, h4, h5, h6, a {
  font-family: 'Source Sans Pro', sans-serif;
  color: #87451f;
}

p, blockquote {
  font-family: 'Playfair Display', serif;
}

#Packages {
  background: url('../img/bird_background.jpg') top center;
}

#Services {
  background: #d1c0ad;
  min-height: 600px;
  padding-bottom: 25px;
}

#OnlineBooking {
  background: #efefef;
}

#Contact, header {
  background: url('../img/bird_background.jpg') center center;
}

#Scenery {
  min-height: 300px;
  background: url('../img/lake_scenery.jpg') center center;
  background-size: cover;
}

header {
  padding-bottom:40px;
}

#banner {
  position: relative;
}
  map a {
    position: absolute;
    right: 106px;
    bottom: 99px;
    height: 54px;
    line-height: 54px;
    width: 353px;
    background: #f2cf87;
    color: #87451f;
    text-align: center;

    &:hover {
      color: #f2cf87;
      background: #87451f;
    }

  }
  .bannerBtn {
    background: #f2cf87;
    color: #87451f;
    text-align: center;

    &:hover {
      color: #f2cf87;
      background: #87451f;
    }

  }
